import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import styled from "@emotion/styled"
import { SECONDARY_BG_TRANSPARENT } from "../../../shared/Colors"
import { Grid } from "@material-ui/core"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement()

const ProfileDetails = ({ profile }) => {
  return (
    <DetailsContainer>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid container item sm={5}>
          <div
            style={{
              width: "100%",
              padding: "1rem",
            }}
          >
            <Image fluid={profile.profileAvatar.fluid} />
          </div>
        </Grid>
        <Grid item md={5} sm={10}>
          <DetailTextContainer>
            <h3>{profile.profileHeading}</h3>
            <h1>{profile.profileTitle}</h1>
            <MDXProvider>
              <MDXRenderer>{profile.article.mdx.body}</MDXRenderer>
            </MDXProvider>
          </DetailTextContainer>
        </Grid>
      </Grid>
    </DetailsContainer>
  )
}

ProfileDetails.propTypes = {
  profile: PropTypes.object,
}

// STYLES
const DetailsContainer = styled.div`
  background-color: ${SECONDARY_BG_TRANSPARENT};
  padding: 2em;
  padding-top: 6em;
  padding-bottom: 3em;
`

const DetailTextContainer = styled.div``

export default ProfileDetails
