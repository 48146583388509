import React from "react"
import Layout from "../shared/layout/Layout"
import Home from "../home/Home"

export default function Index() {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}
