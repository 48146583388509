import React from "react"
import Image from "gatsby-image"
import OutlinedButton from "../../../shared/components/OutlinedButton"
import styled from "@emotion/styled"
import {
  GREY,
  PRIMARY_BG,
  PRIMARY_GREEN,
  SECONDARY_BG_TRANSPARENT,
  WHITE,
} from "../../../shared/Colors"

const ProjectCard = ({ project }) => {
  return (
    <ProjectCardContainer>
      <Image fixed={project.projectImage.fixed} />
      <CardBody>
        <CompanyNameHeading>{project.companyName}</CompanyNameHeading>
        <RoleHeading>{project.title}</RoleHeading>
        <ProjectDetailsText>{project.shortText.shortText}</ProjectDetailsText>
      </CardBody>
      <OutlinedButton href={`projects/${project.slug}`}>
        Read More &rarr;
      </OutlinedButton>
    </ProjectCardContainer>
  )
}

// STYLES
const ProjectCardContainer = styled.div`
  width: 380px;
  height: 580px;
  background-color: ${SECONDARY_BG_TRANSPARENT};
  margin-right: 1rem;
  margin-bottom: 2rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 10px 10px 9px -12px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 9px -12px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 9px -12px rgba(0, 0, 0, 0.75);
  transition: box-shadow 1s ease-in-out;
  transition: border-color 0.5s ease-in-out;
  border-style: solid;
  border-width: thin;
  border-color: ${PRIMARY_BG};

  :hover {
    -webkit-box-shadow: 9px 9px 22px -4px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 9px 9px 22px -4px rgba(0, 0, 0, 0.75);
    box-shadow: 9px 9px 22px -4px rgba(0, 0, 0, 0.75);
    border-style: solid;
    border-width: thin;
    border-color: ${PRIMARY_GREEN};
  }

  &:last-of-type {
    margin-right: 0rem;
  }

  > a {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }

  > a > span {
    font-size: 0.8rem;
  }
`

const CompanyNameHeading = styled.p`
  text-transform: uppercase;
  color: ${PRIMARY_GREEN};
  font-family: "Nanum Gothic";
  text-decoration: overline;
`

const RoleHeading = styled.p`
  color: ${WHITE};
  font-family: "Nanum Gothic";
`

const ProjectDetailsText = styled.p`
  color: ${GREY};
`

const CardBody = styled.div`
  margin: 1.5rem;
`

export default ProjectCard
