import React from "react"
import Hero from "./hero/Hero"
import Profile from "./profile/Profile"
import Projects from "./projects/Projects"
import styled from "@emotion/styled"
/*import Latest from "./latest/Latest"*/
import Clients from "./clients/Clients"

const Home = () => {
  return (
    <>
      <Hero />
      <div className="secondary-segment">
        <PageSegment>
          <Profile />
        </PageSegment>
        <PageSegment>
          <Projects />
        </PageSegment>
      </div>
      <div className="secondary-segment-light">
        <PageSegment>
          <Clients />
        </PageSegment>
      </div>
      {/*<div className="secondary-segment-dark">
        <PageSegment>
          <Latest />
        </PageSegment>
      </div>*/}
    </>
  )
}

// STYLES
const PageSegment = styled.div`
  margin-bottom: 2em;

  &:last-of-type {
    margin-bottom: 0;
  }
`
export default Home
