import { graphql, useStaticQuery } from "gatsby"

const useClients = () => {
  const clientsDataQ = graphql`
    {
      clients: allContentfulClients(
        filter: { node_locale: { eq: "en-US" } }
        limit: 6
      ) {
        nodes {
          clientName
          clientLogo {
            fixed(width: 300) {
              ...GatsbyContentfulFixed_withWebp_noBase64
            }
          }
          link
          node_locale
        }
      }
    }
  `

  const {
    clients: { nodes: clientsData },
  } = useStaticQuery(clientsDataQ)

  return clientsData
}

export default useClients
