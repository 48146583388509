import React from "react"
import { Container } from "@material-ui/core"
import styled from "@emotion/styled"
import useClients from "./hooks/use-clients"
import ClientTile from "./components/ClientTile"

const Clients = () => {
  const clients = useClients()

  return (
    <ClientsContainer num={6}>
      <ClientsTitle>
        <h3>our Honorable</h3>
        <h1>Clients</h1>
      </ClientsTitle>

      <ClientsTileContainer>
        {clients.map(client => (
          <ClientTile key={client.clientName} client={client} />
        ))}
      </ClientsTileContainer>
    </ClientsContainer>
  )
}

const ClientsContainer = styled(Container)`
  margin-top: 3rem;
`

const ClientsTitle = styled.div`
  height: 100%;
  float: left;
`

const ClientsTileContainer = styled.div`
  text-align: right;
`

export default Clients
