import React from "react"
import styled from "@emotion/styled"
import Image from "gatsby-image"
import { DARKER_GREEN, PRIMARY_BG } from "../../../shared/Colors"

const ClientTile = ({ client }) => (
  <a href={client.link}>
    <ClientsImageTile fixed={client.clientLogo.fixed} />
  </a>
)

const ClientsImageTile = styled(Image)`
  border-radius: 8px;
  margin-left: 1.7rem;
  margin-bottom: 1.7rem;
  -webkit-box-shadow: 10px 10px 9px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 10px 10px 9px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 10px 10px 9px -9px rgba(0, 0, 0, 0.75);
  transition: border-color 0.2s ease-in-out;
  border-style: solid;
  border-color: ${PRIMARY_BG};

  :hover {
    border-style: solid;
    border-color: ${DARKER_GREEN};
  }
`

export default ClientTile
