import React from "react"
import styled from "@emotion/styled"
import { Container } from "@material-ui/core"
import { graphql, useStaticQuery } from "gatsby"
import ProjectCard from "./components/ProjectCard"

const Projects = () => {
  const {
    allContentfulProject: { nodes: projectData },
  } = useStaticQuery(projectDataQ)

  return (
    <ProjectsContainer>
      <h3>Consulting</h3>
      <h1>Projects</h1>

      <CardContainer>
        {projectData.map(project => (
          <ProjectCard key={project.title} project={project} />
        ))}
      </CardContainer>
    </ProjectsContainer>
  )
}

// QUERIES
const projectDataQ = graphql`
  {
    allContentfulProject(filter: { node_locale: { eq: "sv" } }) {
      nodes {
        id
        title
        slug
        companyName
        shortText {
          shortText
        }
        projectImage {
          fixed(width: 380) {
            ...GatsbyContentfulFixed
          }
        }
        node_locale
      }
    }
  }
`

// STYLES
const ProjectsContainer = styled(Container)`
  padding-bottom: 4rem;
`

const CardContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`
export default Projects
