import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Grid, Container } from "@material-ui/core"
import styled from "@emotion/styled"
import { PRIMARY_GREEN } from "../../shared/Colors"

const Hero = () => {
  const { contentfulHero: data } = useStaticQuery(getHeroQ)
  return (
    <HeroContainer>
      <Grid container direction="row" justify="center" alignItems="center">
        <div>
          <IntroText>{data.introText}</IntroText>
          <h1>{data.title}</h1>
          <h4>{data.subtitle}</h4>
        </div>
        <Image fixed={data.heroImage.fixed} />
      </Grid>
    </HeroContainer>
  )
}

// QUERIES
const getHeroQ = graphql`
  {
    contentfulHero(node_locale: { eq: "en-US" }) {
      introText
      title
      subtitle
      heroImage {
        fixed(width: 610) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
    }
  }
`

// STYLES
const HeroContainer = styled(Container)`
  margin-top: 10em;
  margin-bottom: 10em;

  h5 {
    color: ${PRIMARY_GREEN};
    font-size: 48px;
    font-family: monospace;
    margin-bottom: 0;
  }

  h1 {
    font-size: 79px;
    margin-top: 0;
    margin-left: ;
    font-family: serif;
    margin-bottom: 0;
  }

  h4 {
    font-size: 22px;
    margin-top: 4px;
    font-family: revert;
    text-decoration: overline;
  }
`

const IntroText = styled.h5`
  text-transform: none;
`

export default Hero
