import React from "react"
import styled from "@emotion/styled"
import { Container, AppBar, Tabs, Tab } from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import ProfileDetails from "./components/ProfileDetails"
import { PRIMARY_BG, PRIMARY_GREEN } from "../../shared/Colors"

const Profile = () => {
  let {
    allContentfulProfile: { nodes: headings },
  } = useStaticQuery(profileHeadingsQ)
  headings = headings.filter(heading => heading.node_locale === "en-US")
  const [value, setValue] = React.useState(0)

  const handleChange = (_, newVal) => {
    setValue(newVal)
  }

  const renderHeadings = () => {
    return headings.map((heading, index) => (
      <StyledTab
        key={heading.id}
        color={value === index ? "filled" : "normal"}
        label={heading.profileHeading}
      />
    ))
  }

  return (
    <ProfileContainer>
      <AppBar position="static" color="transparent">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
        >
          {renderHeadings()}
        </Tabs>
      </AppBar>
      <ProfileDetails profile={headings[value]} />
    </ProfileContainer>
  )
}

// QUERIES
const profileHeadingsQ = graphql`
  {
    allContentfulProfile {
      nodes {
        id
        profileHeading
        profileTitle
        article {
          mdx: childMdx {
            body
          }
        }
        profileAvatar {
          fluid(maxWidth: 400) {
            ...GatsbyContentfulFluid
          }
        }
        node_locale
      }
    }
  }
`

// STYLES
const ProfileContainer = styled(Container)``

const StyledTab = styled(Tab)`
  height: 4em;
  font-size: 19px;
  font-weight: 600;
  color: #cacaca;
  background-color: ${PRIMARY_BG};
  opacity: 1;
  border-right: 0.1px solid #d0d0d01a;
  border-left: 0.1px solid #d0d0d01a;

  ${({ color }) =>
    color === "filled" &&
    `
    color: black;
    background-color: ${PRIMARY_GREEN};
    border-right: none;
    border-left: none;
  `}
`

export default Profile
